import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar'
import Home_slider from '../Home_slider/Home_slider'
import https from '../https';
import './Services.css';
import Logo from '../../assets/logo.webp';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';

export default function Services() {
    const [Data, setData] = useState([]);
    const [t,il8n]=useTranslation();

    useEffect(() => {
  
      https.get('Services/Get').then(
          res =>{
            
            setData(res.data)
              
          }
      )        
  
  }, []);
  
  return (
    <div className='Services'>
      <NavBar/>
      <Home_slider/>
      <div className="container ">
        <h1>{t('services')}</h1>
        <br />
        <div className="services_content">
        {   
                    Data.map((item,index)=>{
                        return (
                            <div key={index} className="item">
                                <div className='image'>
                                    <img src={Logo} alt="" />
                                </div>
                                <div className="price">

                                    <h3>{il8n.language=='en'? item.title:item.title_ar}</h3>
                                    <h4>SAR {item.price} </h4>
                                </div>
                            </div>
                        )
                    })
        }

        </div>
      </div>
      <br />
      <br />
      <Footer/>
    </div>
  )
}
