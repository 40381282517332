import React, { useEffect, useState } from 'react'
import Avtar from '../../assets/avtar.png';
import './salon_team.css';
import { FaStar } from 'react-icons/fa';
import https from '../https';
import Slider from 'react-slick';

export default function Salon_team() {
  const [Team, setTeam] = useState([]);
  useEffect(() => {

    https.get('Team/Get').then(
        res =>{
          setTeam(res.data)
            
        }
    )        

}, []);
var settings_Slider_brand = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 100,
  cssEase: "linear"
  
};

    return (
    <div className='salon_team container'>

       <Slider {...settings_Slider_brand}>
       {
        Team.map((item,index)=>{
            return (
                <div key={index} className="item_team">
                    {/* <img src={Avtar} alt="" /> */}
                    <h3>{item.name}</h3>
                    <div className="rate">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />

                    </div>
                </div>
            )
        })
      }

      </Slider>
    </div>
  )
}
